.menuTeacher {
  background: #a0460a;
  color: #ffffff;
}

.werdk {
  margin-top: 3%;
  font-weight: bold;
  color: #eb4a00;
  background-color: rgba(184, 83, 1, 0.445);
  padding: 8px;
  border-radius: 10px;
  margin-bottom: -10px;
}
