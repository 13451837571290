/* @import url(https://fonts.googleapis.com/earlyaccess/thabit.css); */

/*HERO*/
@media (min-width: 768px) {
  #hero {
    padding: 0;
  }
}

@media (max-width: 768px) {
  #hero {
    margin-top: -20px;
    border-top: none;
    padding: 0;
    height: 500px;
    background-image: url("../../public/images/heroMob.jpg");
    background-position: center;
    background-size: 100ch;
    background-repeat: no-repeat;
  }
}

/*ADVICE SECTION*/
#advice {
  margin-top: 20px;
  /*background: repeating-radial-gradient(circle, #ffe6d7, transparent 20%);*/
  background-size: 1em 1em;
  background-color: #ffffff;
  opacity: 1;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 5%;
  padding-right: 5%;
}

#advice2 {
  margin-top: 20px;
  /*background: repeating-radial-gradient(circle, #ffe6d7, transparent 20%);*/
  background-size: 1em 1em;
  background-color: #ffffff;
  opacity: 1;
  padding-bottom: 20px;
  padding-left: 5%;
  padding-right: 5%;
}

.advicetitle {
  margin-bottom: 40px;
  padding-top: 2%;
  text-align: center;
  color: #172153;
  font-weight: 700;
}

.advicetext {
  margin-top: 20px;
  text-align: center;
  color: #242424;
  font-weight: 600;
}
.advicecard {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #f05d0e;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
  padding-right: 5px;
  padding-left: 5px;
  height: 350px;
}
.advicecard:hover {
  transform: scale(1.1);
}
.advicepic {
  width: 100px;
  height: 100px;
}

/*SIGN SECTION */
#signin {
  margin-top: 20px;
  margin-bottom: 20px;
}
.signcard {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.signtext {
  padding: 10px;
  font-weight: 700;
  width: 400px;
  color: #000000;
}
.signbtn {
  width: 30%;
  border-radius: 5px;
  margin-bottom: 30px;
  background-color: rgba(47, 61, 64, 0);
  border-radius: 6px;
  border: 1px solid #172153;
  font-weight: bold;
  color: #f05d0e;
  height: 40px;
  text-decoration: none;
  padding-top: 5px;
}
.signbtn:hover {
  background-color: #ffcfaf;
  border: 1px solid;
  color: #f05d0e;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}
.signpic {
  padding: 10px;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

/* banner */

#banner-container {
  background-color: #172153;
  padding-top: 80px;
  padding-bottom: 80px;
  color: #fff;
}

@media only screen and (min-width: 780px) {
  #banner-row {
    margin-left: 50px;
  }
}

#banner-row img {
  max-width: 70%;
  display: block;
}

#banner-row .welcomming,
.secondTextWelcome {
  padding-left: 20px;
  padding-top: 20px;
  text-align: center;
}

#banner-row a {
  color: black !important;
  border: none;
  margin-left: 20px;
  margin-top: 20px;
}
#banner-col {
  padding-left: 20px;
}
/* service */
#service {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #fcf6ed;
}
#timeline {
  background-color: #fffcf8;
}

#momayizat {
  background-color: #f5f2ff;
}

#barnamaj {
  background-color: #fffcf8;
}

#contactUs {
  background-color: #f5f2ff;
}

#serviceThree {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f5f2ff;
}

/* about */

#about {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #d8deff;
  color: #424242;
}

#about h1 {
  padding-bottom: 70px;
}

#about-col ul {
  padding-top: 50px;
  padding-left: 50px;
}

#about-col ul li {
  padding-top: 15px;
}

.homeServices {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 50px -14px rgba(0, 0, 0, 0.8);
  margin: 10px;
  border-radius: 10px;
  width: 450px;
}

/* product */

#product {
  padding-top: 80px;
  padding-bottom: 80px;
  /* background-color: #f05d0e; */
  background: repeating-radial-gradient(circle, #ffe6d7, transparent 20%);
  background-size: 1em 1em;
  background-color: #ffffff;
  opacity: 1;
  /* color: #fff; */
  color: #1a1a1a;
}

#product h1 {
  padding-bottom: 70px;
}
/*   
#product-col2 ul{
    padding-top: 90px;
} */
.homeList {
  direction: rtl;
  margin-right: 20px;
}

#product-col2 ul li {
  padding-top: 15px;
}

/* social */

#social {
  padding-top: 80px;
  padding-bottom: 80px;
}

#social h1 {
  padding-bottom: 70px;
}

.social-col a:hover img {
  transform: translateY(-10px);
}

#social-row {
  flex-direction: row;
}

/* footer */
.sec-footer {
  background: #433d7b;
  direction: rtl;
  text-align: right;
  color: #fff;
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}
.sec-footer .desc-box {
  background: transparent;
  border: 0;
}

.card-title {
  color: #f05d0e !important;
}
.sec-footer .desc-box .card-title::after {
  content: " ";
  background: #ffffff;
  display: block;
  width: 95%;
  height: 0.2rem;
  margin-top: 0.3rem;
}
.sec-footer .desc-box .card-text {
  text-align: justify;
}
.sec-sub-footer {
  background: #f2f2f4;
  direction: rtl;
  text-align: right;
  color: #000;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 0.8rem;
}
.sec-sub-footer a {
  font-size: 0.8rem;
}
/*------------------*/
.snip1472 {
  position: relative;
  font-size: 20px;
  color: #e6e6e6;
  width: 45px;
  margin: 4px;
  height: 45px;
  line-height: 45px;
  display: inline-block;
  text-align: center;
  -webkit-perspective: 50em;
  perspective: 50em;
  text-decoration: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.snip1472:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 2px solid #e6e6e6;
  content: "";
  z-index: -1;
  border-radius: 50%;
}
.snip1472:before {
  color: #fff;
}
.snip1472:before,
.snip1472:after {
  -webkit-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.snip1472:hover,
.snip1472:active,
.snip1472.hover {
  color: #f05d0e;
}
.snip1472:hover:after,
.snip1472:active:after,
.snip1472.hover:after {
  border-color: #f05d0e transparent #f05d0e transparent;
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.footerLogo {
  margin-top: -15%;
  width: 10rem;
  height: 10rem;
}
.ayaFooter {
  margin-top: -5%;
}
.jami37ou9ou9 {
  text-align: center;
  font-weight: bolder;
  font-size: large;
  color: #117eb1;
  margin-top: -20px;
}

.mb-3 {
  padding-top: 10px;
}

/* media */
@media only screen and (max-width: 987px) {
  .navbar-brand {
    padding-left: 0px;
  }
  .homeServices {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 50px -14px rgba(0, 0, 0, 0.8);
    margin: 10px;
    border-radius: 10px;
    max-width: 90%;
    margin-left: 17px !important;
  }
}

.btnHome {
  font-size: 30px;
  font-weight: bolder;
  color: #172153;
  margin: 10px;
  background-color: rgb(206, 240, 255);
  width: 170px;
  height: 60px;
  border-radius: 10px;
  border: solid 3px #005b85;
}

.btnHome:hover {
  background-color: #f05d0e;
  border: solid 3px #ffffff;
  color: #ffffff;
}

.welcomming {
  font-size: 60px;
}

.quraniWord {
  color: #f05d0e;
  direction: rtl;
  margin-right: 20px;
}

.homeParagraphs {
  font-weight: 600;
  word-spacing: 5px;
  direction: rtl;
}
.threeParts {
  direction: rtl;
  margin-right: 20px;
  color: #f05d0e;
}

.myBlueColor {
  color: #172153;
  direction: rtl;
  margin-right: 75px;
}

/* customized scroll bar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #117eb1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #0e6188;
}

.img-responsive {
  margin-top: 5%;
}
.arabicText {
  direction: rtl;
  margin-right: 5%;
}
#hadithExp {
  color: #5c5c5c;
}
#hadith {
  background-color: #f5f2ff;
  font-weight: 900;
  line-height: 53px;
  padding: 2%;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.taahaduWord {
  color: #f05d0e;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.goToTopButton {
  display: none;
}

.goToTopButton:hover {
  background-color: #e86f3c;
}

@media (min-width: 968px) {
  .goToTopButton {
    margin-right: -35%;
  }
  .goToTopButton {
    border: none;
    outline: none;
    background-color: #ff935a;
    color: white;
    padding: 5px 10px;
    width: 10%;
    height: 6%;
    position: absolute;
    display: inline-block;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
}
