.presContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.presTitleCard {
  display: flex;
  justify-content: center;
  margin: 20px;
  height: 250px;
  width: 550px;
}

.presTitle {
  text-align: center;
  margin-top: 50px;
  color: #172153;
  font-weight: 700;
  width: 400px;
}

.presCard {
  display: flex;
  background-color: rgb(228, 228, 228);
  justify-content: center;
  margin: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  width: 550px;
  padding: 10px !important;
  border-radius: 10px;
  direction: rtl;
}

.presentationtitle {
  margin-right: 15px;
  color: #172153;
  font-weight: 700;
}

.presentationtext {
  margin-right: 15px;
  color: #272727;
  font-weight: 600;
}

.presentationhalf2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: right;
}

.presentationhalf1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.presentationpic {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 220px;
  height: 200px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

@media (max-width: 768px) {
  .presContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .presCard {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    width: 350px;
    border-radius: 10px;
    padding: 5px;
    direction: rtl;
    margin-left: 5% !important;
  }

  .presTitleCard {
    display: flex;
    justify-content: center;
    margin: 20px;
    height: 100px;
    width: 350px;
  }

  .presentationpic {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 300px;
    height: 280px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  .presTitle {
    margin-top: -5px;
    text-align: center;
    color: #172153;
    font-weight: 700;
  }
}
