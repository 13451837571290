.sideNav {
  /* background: #f5f7f8; */
  background: radial-gradient(
      circle at top,
      transparent 60%,
      #fff0dc 61%,
      #fff0dc 66%,
      transparent 67%
    ),
    radial-gradient(
      circle at bottom,
      transparent 60%,
      #fff0dc 61%,
      #fff0dc 66%,
      transparent 67%
    ),
    radial-gradient(
      circle at left,
      transparent 60%,
      #fff0dc 61%,
      #fff0dc 66%,
      transparent 67%
    ),
    radial-gradient(
      circle at right,
      transparent 60%,
      #fff0dc 61%,
      #fff0dc 66%,
      transparent 67%
    );
  background-size: 1em 1em;
  background-color: #ffffff;
  opacity: 1;
  margin: 0;
  color: #172153;
  padding-left: 240px;
  height: 100% !important;
  padding-top: 20px;
  padding-bottom: 20px;
}
main {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  width: auto !important;
}
main .helper {
  background: rgba(0, 0, 0, 0.2);
  color: #f56f46;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  padding: 1.2em 2em;
  text-align: center;
  border-radius: 20px;
  font-size: 2em;
  font-weight: bold;
  width: auto !important;
}
main .helper span {
  color: rgba(0, 0, 0, 0.2);
  font-size: 0.4em;
  display: block;
}
.menu {
  background: #172153;
  color: #ffffff;
  height: 100vh;
  width: 240px;
  position: fixed;
  top: 0;
  left: 0; /*  right to change sidebar to be on the right  */
  z-index: 5;
  outline: none;
}
.menu .avatar {
  background: rgba(0, 0, 0, 0.1);
  padding: 1em 0.5em;
  text-align: center;
}
.menu .avatar img {
  width: 150px;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #b4b4b4;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
}
.menu .avatar h2 {
  font-weight: normal;
  margin-bottom: 0;
}
.menu ul {
  list-style: none;
  padding: 0.5em 0;
  margin: 0;
}
.menu ul li {
  padding: 0.5em 1em 0.5em 3em;
  font-size: 0.95em;
  font-weight: regular;
  background-repeat: no-repeat;
  background-position: left 15px center;
  background-size: auto 20px;
  transition: all 0.15s linear;
  cursor: pointer;
}

/* student side icons : */
.menu ul li.icon-dashboard {
  background-image: url("https://cdn-icons-png.flaticon.com/128/9539/9539826.png");
}
.menu ul li.icon-werd {
  background-image: url("https://cdn-icons-png.flaticon.com/128/7298/7298889.png");
}
.menu ul li.icon-hefdh {
  background-image: url("https://cdn-icons-png.flaticon.com/128/384/384372.png");
}
.menu ul li.icon-test {
  background-image: url("https://cdn-icons-png.flaticon.com/128/4711/4711174.png");
}
.menu ul li.icon-course {
  background-image: url("https://cdn-icons-png.flaticon.com/128/8735/8735661.png");
}
.menu ul li.icon-settings {
  background-image: url("https://cdn-icons-png.flaticon.com/128/9546/9546452.png");
}
.menu ul li.icon-logout {
  background-image: url("https://cdn-icons-png.flaticon.com/128/5785/5785427.png");
}

/* Teacher side icons : */
.menu ul li.icon-students {
  background-image: url("https://cdn-icons-png.flaticon.com/128/3369/3369157.png");
}
.menu ul li.icon-groups {
  background-image: url("https://cdn-icons-png.flaticon.com/128/2936/2936774.png");
}
.menu ul li.icon-exams {
  background-image: url("https://cdn-icons-png.flaticon.com/128/2059/2059613.png");
}

.menu ul li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.menu ul li:focus {
  outline: none;
}
@media screen and (max-width: 900px) and (min-width: 400px) {
  /* body {
    padding-left: 90px;
  } */
  .menu {
    width: 90px;
  }
  .menu .avatar {
    padding: 0.5em;
    position: relative;
  }
  .menu .avatar img {
    width: 60px;
  }
  .menu .avatar h2 {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 100px;
    margin: 0;
    min-width: 200px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.4);
    transform: translate3d(-20px, -50%, 0);
    transition: all 0.15s ease-in-out;
  }
  .menu .avatar:hover h2 {
    opacity: 1;
    transform: translate3d(0px, -50%, 0);
  }
  .menu ul li {
    height: 60px;
    background-position: center center;
    background-size: 30px auto;
    position: relative;
  }
  .menu ul li span {
    opacity: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    padding: 0.2em 0.5em;
    border-radius: 4px;
    top: 50%;
    left: 80px;
    transform: translate3d(-15px, -50%, 0);
    transition: all 0.15s ease-in-out;
  }
  .menu ul li span:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: 50%;
    left: -5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid rgba(0, 0, 0, 0.5);
    transform: translateY(-50%);
  }
  .menu ul li:hover span {
    opacity: 1;
    transform: translate3d(0px, -50%, 0);
  }

  .sideNav {
    background: radial-gradient(
        circle at top,
        transparent 60%,
        #fff0dc 61%,
        #fff0dc 66%,
        transparent 67%
      ),
      radial-gradient(
        circle at bottom,
        transparent 60%,
        #fff0dc 61%,
        #fff0dc 66%,
        transparent 67%
      ),
      radial-gradient(
        circle at left,
        transparent 60%,
        #fff0dc 61%,
        #fff0dc 66%,
        transparent 67%
      ),
      radial-gradient(
        circle at right,
        transparent 60%,
        #fff0dc 61%,
        #fff0dc 66%,
        transparent 67%
      );
    background-size: 1em 1em;
    margin: 0;
    color: #172153;
    padding-left: 80px;
  }
}
@media screen and (max-width: 400px) {
  body {
    padding-left: 0;
  }
  .menu {
    width: 230px;
    box-shadow: 0 0 0 100em rgba(0, 0, 0, 0);
    transform: translate3d(-230px, 0, 0);
    transition: all 0.3s ease-in-out;
  }
  .menu .smartphone-menu-trigger {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 100%;
    background: #172153;
  }
  .menu .smartphone-menu-trigger:before,
  .menu .smartphone-menu-trigger:after {
    content: "";
    width: 45%;
    height: 2px;
    background: #fff;
    border-radius: 10px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  .menu .smartphone-menu-trigger:after {
    top: 55%;
    transform: translate3d(-50%, -50%, 0);
  }
  .menu ul li {
    padding: 1em 1em 1em 3em;
    font-size: 1.2em;
  }
  .menu:focus {
    transform: translate3d(0, 0, 0);
    box-shadow: 0 0 0 100em rgba(0, 0, 0, 0.6);
  }
  .menu:focus .smartphone-menu-trigger {
    pointer-events: none;
  }

  .sideNav {
    /* background: #d3d3d3; */
    background: radial-gradient(
        circle at top,
        transparent 60%,
        #fff0dc 61%,
        #fff0dc 66%,
        transparent 67%
      ),
      radial-gradient(
        circle at bottom,
        transparent 60%,
        #fff0dc 61%,
        #fff0dc 66%,
        transparent 67%
      ),
      radial-gradient(
        circle at left,
        transparent 60%,
        #fff0dc 61%,
        #fff0dc 66%,
        transparent 67%
      ),
      radial-gradient(
        circle at right,
        transparent 60%,
        #fff0dc 61%,
        #fff0dc 66%,
        transparent 67%
      );
    background-size: 1em 1em;
    opacity: 1;
    margin: 0;
    color: #000a61;
    padding-left: 10px;
  }
}

.LoggedInUserEmail {
  margin-top: 20px;
  direction: ltr !important;
}

#activeSection {
  background-color: #4144d673;
  color: #ff8800;
}

.changeProfileImageBtn {
  width: 90%;
  color: #ffffff;
  background-color: #676fa3;
  font-size: smaller;
  height: 15%;
  width: 70%;
  padding: 2%;
  border: #ffffff solid 2px;
  margin-left: 15%;
}

.changeProfileImageBtn:hover {
  background-color: #4e4488;
}
