@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);

#calendar {
  color: #172153 !important;
  box-shadow: 0 0 50px -14px rgba(0, 0, 0, 0.8);
}

.calendarTable {
  background-color: #fff;
}

#calendar .monthName {
  background: #676fa3;
  border-radius: 5px 5px 0 0;
  padding: 10px !important;
  font-size: 140%;
  font-weight: bold !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  cursor: default;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

#calendar table {
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-spacing: 0;
  border-radius: 0 0 5px 5px;
}

#calendar td {
  width: 30px !important;
  height: 38px;
  background: #eee;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 6px;
  cursor: pointer;
  transition: background 0.3s;
  -webkit-transition: background 0.3s;
}

#calendar td:hover:not(.yes) {
  opacity: 0.8;
}

#calendar td:hover:not(.no) {
  opacity: 0.8;
}

#calendar .lastmonth,
#calendar .nextmonth,
#calendar .nextmonth ~ * {
  background: #fff;
  color: #999;
}

#calendar .yes {
  background: #36bd3d !important;
  font-weight: 700;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#calendar .no {
  background: #bb5b5b;
  font-weight: 700;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#calendar .nothing {
  background: #a3a3a3;
  font-weight: 700;
  color: #a3a3a3;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#calendar .hastask {
  font-weight: 100;
}

#calendar tr:last-of-type td:first-of-type {
  border-radius: 0 0 0 5px;
}
#calendar tr:last-of-type td:last-of-type {
  border-radius: 0 0 5px 0;
}
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);

#calendar {
  width: 340px;
  margin: 0 auto;
  margin-bottom: 2%;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  color: #555;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border: solid 3px #ffffff;
  margin-top: 20px;
}

#calendar .monthName {
  background: #676fa3;
  border-radius: 5px 5px 0 0;
  padding: 20px;
  font-size: 140%;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  cursor: default;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

#calendar table {
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-spacing: 0;
  border-radius: 0 0 5px 5px;
}

#calendar td {
  width: 38px;
  height: 38px;
  background: #eee;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 6px;
  cursor: pointer;
  transition: background 0.3s;
  -webkit-transition: background 0.3s;
}

#calendar td:hover:not(.yes) {
  opacity: 0.8;
}

#calendar td:hover:not(.no) {
  opacity: 0.8;
}

#calendar .lastmonth,
#calendar .nextmonth,
#calendar .nextmonth ~ * {
  background: #fff;
  color: #999;
}

#calendar .yes {
  background: #36bd3d;
  font-weight: 700;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#calendar .no {
  background: #bb5b5bf6;
  font-weight: 700;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#calendar .nothing {
  background: #807676;
  font-weight: 700;
  color: #807676;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#calendar .hastask {
  font-size: 13px;
  font-weight: bold;
}

#calendar tr:last-of-type td:first-of-type {
  border-radius: 0 0 0 5px;
}
#calendar tr:last-of-type td:last-of-type {
  border-radius: 0 0 5px 0;
}

.backgroundColor {
  width: 200px;
}

.werdModal {
  background-color: #2c2c2cc0;
  color: #ffffff;
  width: 30%;
  height: auto !important;
  border: black solid 3px;
  border-radius: 12px;
  padding: 2%;
  height: 30%;
  margin-left: 42%;
  margin-top: 14%;
  position: relative;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.closeBtnWerd {
  color: rgb(255, 255, 255);
  background-color: #f56f46;
  margin-left: 80%;
  position: absolute;
  margin-top: -60px;
  font-weight: bold;
}
.closeBtnWerd:hover {
  color: rgb(255, 219, 173);
  background-color: #f06136;
}

@media screen and (max-width: 768px) {
  .werdModal {
    width: 80%;
    margin-left: 10%;
    margin-top: 45%;
    height: 30%;
  }
  .closeBtnWerd {
    color: rgb(255, 255, 255);
    background-color: #f56f46;
    margin-left: 75%;
    position: absolute;
    margin-top: -40px;
    font-weight: bold;
  }
}
