.studentAuth {
  padding: 0;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  font-family: "Jost", sans-serif;
  background: #fcf6ed;
}
.main {
  width: 380px;
  height: 640px;
  background: #ffffff;
  color: #172153;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 5px 20px 50px #000;
  margin-top: 5%;
  padding-right: 0px !important;
}
#chk {
  display: none;
}
.signup {
  position: relative;
  width: 100%;
  height: 100%;
  color: #172153;
}
label {
  color: #172153;
  font-size: 2.3em;
  justify-content: center;
  display: flex;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}
.inputRegistration {
  height: 50px;
  width: 82%;
  background-color: #e0dede;
  color: #000000;
  justify-content: center;
  display: flex;
  margin: 10px auto;
  padding: 10px;
  border: none;
  outline: none !important;
  border-radius: 5px;
  direction: rtl;
}
#selectReg {
  height: 50px;
  color: #000000;
}
#selectRegi selectRegi2 {
  height: 50px;
  width: 82%;
  color: #000000;
}

#email {
  height: 50px;
  width: 150%;
  align-items: center;
  justify-content: center;
  margin-left: 20%;
  color: #000000;
}

.registrationBtn {
  width: 60%;
  height: auto;
  padding: 7px;
  margin: 10px auto;
  justify-content: center;
  display: block;
  color: #fff;
  background: #172153;
  font-size: 1em;
  font-weight: bold;
  margin-top: 20px;
  outline: none;
  border: none;
  border-radius: 5px;
  transition: 0.2s ease-in;
  cursor: pointer;
}
.registrationBtn:hover {
  background: #293988;
}
.login {
  height: 460px;
  background: #f56f46;
  border-radius: 60% / 10%;
  transform: translateY(-180px);
  transition: 0.8s ease-in-out;
}
.login label {
  color: #172153;
  transform: scale(0.6);
}

#chk:checked ~ .login {
  transform: translateY(-600px);
}
#chk:checked ~ .login label {
  transform: scale(1);
}
#chk:checked ~ .signup label {
  transform: scale(0.6);
}
.signupInputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.backword {
  /* font-size: large; */
  border-radius: 5px;
  margin: 2px;
  background-color: #f56f46;
  color: #fff;
  font-weight: bold;
  padding-bottom: 10px !important;
  padding-top: 8px !important;
}

.backword:hover {
  background-color: #ff8560;
  color: #ffffff;
}

.loginPassword {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.errorMsg {
  margin: 0;
  color: #ff2c2c;
  font-size: 15px !important;
}

/* teacher auth */
/* 
*
*
*
*
*
*/

.form {
  overflow: auto;
  height: 700px;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  position: relative;
  box-sizing: border-box; /* Added for proper sizing */
}

@media (max-width: 420px) {
  .form {
    max-width: 100%;
    height: 800px;
    overflow: auto;
    /* height: 800px; */
  }
  #idfelx {
    margin-bottom: 45px !important;
  }
}

.title {
  font-size: 28px;
  color: royalblue;
  font-weight: 600;
  letter-spacing: -1px;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-left: 0; /* Reset padding */
  padding-right: 30px; /* Adjusted for Arabic */
}

.title::before,
.title::after {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  border-radius: 50%;
  right: 0px; /* Adjusted for Arabic */
  background-color: royalblue;
}

.title::before {
  width: 18px;
  height: 18px;
  background-color: royalblue;
}

.title::after {
  width: 18px;
  height: 18px;
  animation: pulse 1s linear infinite;
}

.message,
.signin {
  color: rgba(88, 87, 87, 0.822);
  font-size: 17px;
  direction: rtl;
}

.signin a {
  color: royalblue;
}

.signin a:hover {
  text-decoration: underline;
}

.flex {
  flex-direction: row-reverse;
  display: flex;
  width: 100%;
  gap: 10px;
  margin-bottom: 5px; /* Added for spacing */
}

.form label {
  width: 90%;
  position: relative;
  display: flex;
  flex-direction: column; /* Place the label on top */
  align-items: flex-end; /* Align to the right side */
  margin-bottom: 8px; /* Add spacing between labels */
}

.form label .input {
  width: 100%;
  height: 3rem;
  outline: 0;
  font-size: 20px !important;
  border: 1px solid rgba(105, 105, 105, 0.397);
  border-radius: 10px;
  direction: rtl;
}

.form label .input + span {
  position: absolute;
  right: 10px; /* Adjusted for Arabic */
  top: 15px;
  color: grey;
  font-size: 0.9em;
  cursor: text;
  transition: 0.3s ease;
}

.form label .input:placeholder-shown + span {
  top: 15px;
  font-size: 0.9em;
}

.form label .input:focus + span,
.form label .input:valid + span {
  top: 30px;
  font-size: 0.7em;
  font-weight: 600;
}

.form label .input:valid + span {
  color: rgb(0, 34, 128);
}
#teacherAuthInput {
  width: 82%;
}

#emailfieldStudent {
  direction: ltr !important;
}
.emailfieldStudent {
  width: 82% !important;
}

#emailfield {
  direction: ltr !important;
  width: 82%;
}

#marginLeft {
  margin-left: 40%;
  margin-top: 5%;
}

.password-label {
  display: flex;
  flex-direction: column;
}

.password-input {
  display: flex;
  align-items: center;
}

.password-toggle {
  color: rgba(65, 105, 225, 0.692) !important;
  margin-right: 70%;
  cursor: pointer !important;
  position: fixed;
}
.submit {
  border: none;
  outline: none;
  background-color: royalblue;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  transition: 0.3s ease;
  direction: rtl;
  cursor: pointer;
  margin-bottom: 5%;
  margin-top: 3%;
}

.submit:hover {
  background-color: rgb(56, 90, 194);
}

.insha2-hisab {
  border: none;
  outline: none;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  color: royalblue;
  font-size: 16px;
  transition: 0.3s ease;
  direction: rtl;
  cursor: pointer;
}
.insha2-hisab:hover {
  background-color: rgb(219, 227, 241);
}

#marginTop {
  margin-top: 10%;
}

@keyframes pulse {
  from {
    transform: scale(0.9);
    opacity: 1;
  }

  to {
    transform: scale(1.8);
    opacity: 0;
  }
}
.teacherFormLabels {
  font-size: 19px !important;
}
.subBtnTeacher {
  text-align: center;
}
