.tracherMonthName {
  background: #d17600;
  border-radius: 5px 5px 0 0;
  padding: 20px;
  font-size: 140%;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  cursor: default;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

#calendar .tracherMonthName {
  background: #d17600;
  border-radius: 5px 5px 0 0;
  padding: 10px !important;
  font-size: 140%;
  font-weight: bold !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  cursor: default;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}
