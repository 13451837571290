.qurani-feedback-container {
  margin: auto;
  max-width: 600px;
  padding: 10px;
  border: 2px solid #172153;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding-top: 35px;
  margin-bottom: 25px;
}
.qurani-feedback-header {
  text-align: center;
  color: #172153;
}

#feedback {
  height: 10rem;
  background-color: #e0dede;
  direction: rtl;
}

@media screen and (min-width: 700px) {
  #feedback {
    width: 20rem;
  }
}

#email {
  width: 100%;
  margin-right: 25%;
  background-color: #e0dede;
}

.qurani-feedback-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qurani-feedback-inputs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.qurani-feedback-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qurani-feedback-input-container label {
  font-size: 25px;
  margin-bottom: 5px;
  color: #161616;
  direction: rtl;
}

.qurani-feedback-input-container input,
.qurani-feedback-input-container textarea {
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
}

.qurani-feedback-rating-container {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.qurani-feedback-rating-container label {
  margin: 0 5px;
  cursor: pointer;
}

.star-icon {
  font-size: 25px;
  transition: color 0.25s;
}

.qurani-feedback-submit-button {
  background-color: #172153;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.25s;
  margin-top: 20px;
}

.qurani-feedback-submit-button:hover {
  background-color: #f56f46;
}

.qurani-feedback-error,
.qurani-feedback-success {
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
  color: #f56f46;
}

.qurani-feedback-error {
  color: #f00;
}
