.profileImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid rgba(194, 106, 34, 0.301);
  position: relative;
  top: -40px;
  margin-right: 10%;
}

.singleProfile {
  background: rgba(194, 106, 34, 0.301);
  padding: 3%;
  margin: 2%;
  max-width: 400px;
  max-height: 350px;
  margin-top: 5%;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: transform 0.2s ease-in-out;
  padding-left: 3%;
  position: relative; /* Ensure proper stacking of elements within .singleProfile */
  direction: rtl;
}

/* .singleProfile:hover {
  transform: scale(1.04);
} */

#singleProfileID {
  margin-left: 30%;
  margin-top: 30px;
}

.studentName {
  margin-top: 10%;
  color: #172153;
  direction: rtl;
}

.ahzab {
  /* margin-top: -35%; */
  margin-right: 70%;
  color: #007ab3;
}

.StudentDetailsBtn {
  clear: both;
  text-align: center;
  width: 60%;
  margin-right: 22%;
  height: 35px;
  background: #f17108;
  color: #fff;
  line-height: 38px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 10px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin-top: 3%;
}

.StudentDetailsBtn:hover {
  background: #ff8623;
}

#StudentDetailsBtn {
  background: #007ab3;
}

#StudentDetailsBtn:hover {
  background: #0c9adb;
}

.searchBox {
  display: flex;
  max-width: 250px;
  margin-right: 50px;
  margin-top: 5px;
  margin-bottom: 3%;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  background-color: rgba(62, 125, 189, 0.87);
  border: #006efd solid 2px;
  border-radius: 50px;
  position: relative;
}

.searchInput::placeholder {
  color: rgb(202, 202, 202);
}

.card-body2 {
  margin-top: -30%;
}

#cardName {
  color: black !important;
  margin-top: 7%;
}

#majmouatCard {
  background-color: #007ab328;
}

#addToGroup {
  width: 50%;
}

.searchButton {
  color: white;
  position: absolute;
  right: 188px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(
    --gradient-2,
    linear-gradient(90deg, #46bef5 0%, #006efd 100%)
  );
  border: 0;
  display: inline-block;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
/*hover effect*/
/* button:hover {
  background-color: #1a1a1a;
  box-shadow: rgba(0, 0, 0, 0.5) 0 10px 20px;
  transform: translateY(-1px);
} */
/*button pressing effect*/
button:active {
  box-shadow: none;
  transform: translateY(0);
}

.searchInput {
  border: none;
  color: #fff;
  font-weight: bold;
  background: none;
  direction: rtl !important;
  margin-right: 15px;
  outline: none;
}

.werdStudent {
  margin-top: 3%;
  font-weight: bold;
  color: #172153;
  background-color: rgba(1, 126, 184, 0.445);
  padding: 8px;
  border-radius: 10px;
  margin-bottom: -10px;
  text-align: center;
  align-items: center;
}
@media screen and (min-width: 700px) {
  #hefdhStudent {
    margin-left: 22%;
    width: 50%;
    height: 20%;
  }
}
@media screen and (max-width: 700px) {
  #hefdhStudent {
    height: 20%;
    margin-top: 20%;
  }
  .searchInput {
    border: none;
    color: #fff;
    font-weight: bold;
    background: none;
    direction: rtl !important;
    margin-right: 35px !important;
    outline: none;
  }
  .searchBox {
    margin-right: 20px;
  }
}

#marginBTMBackBtn {
  margin-bottom: 20px;
  position: absolute;
  top: 5px;
  left: 10px;
  display: flex;
  align-items: flex-start;
}
.flexBackBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .marginBtmYearView {
    margin-top: 20%;
  }
}

.filterMajmouaat {
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 3%;
  height: 60px;
  background-color: rgba(62, 125, 189, 0.87);
  color: #ffffff;
  justify-content: center;
  display: flex;
  padding: 5px;
  border: none;
  outline: none !important;
  border-radius: 5px;
  direction: rtl;
}
