.majmouaat {
  color: rgb(7, 0, 104);
  font-weight: bold;
}
#addMajmouaa {
  background-color: #007ab3;
  height: 40px;
  width: 180px;
}

#addMajmouaa:hover {
  background-color: #008fd1;
}

#groupName {
  height: 50px;
  width: 250px;
  margin-right: 5%;
  font-weight: bold;
  margin-bottom: 10px;
  background-color: rgba(62, 125, 189, 0.87);
  color: rgb(255, 255, 255);
}
#groupName::placeholder {
  color: rgb(202, 202, 202);
}
.studentsToAdd {
  padding-top: 2%;
  height: 340px;
  overflow: auto;
}

@media (max-width: 768px) {
  .studentsToAdd {
    padding-top: 5%;
    margin-top: 30px;
    height: 550px;
  }

  #notArabicText {
    direction: ltr;
    margin-right: -50px;
  }

  #groupName {
    margin-bottom: 5%;
    margin-top: 5%;
  }
}

#notArabicText {
  direction: ltr;
}
