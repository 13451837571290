.videoModal {
  background-color: #d3d3d3;
  /* border: #f56f46 3px solid; */
  width: 70%;
  height: auto;
  margin-left: 22%;
  margin-top: 5%;
  position: relative;
}

@media screen and (max-width: 768px) {
  .videoModal {
    width: 95%;
    margin-left: 2.5%;
    margin-top: 25%;
    height: auto;
  }
  .closeBtn {
    color: rgb(255, 255, 255);
    background-color: #f56f46;
    margin-left: 83%;
    position: absolute;
    margin-top: -30px;
    font-weight: bold;
  }
}

.qalun {
  padding: 2%;
  align-items: center;
  border-radius: 10px;
  direction: rtl;
  background-color: rgba(96, 109, 219, 0.212);
  margin: 0 auto; /* Add margin property to center the div horizontally */
}

.qalunText {
  width: 80%;
  font-size: 20px;
  margin-right: -2%;
}

.qalunPic {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 10px;
  border: #f06136 2px solid;
}

.qalunPicContainer {
  position: relative;
}

.qalunTextOverlay {
  position: absolute;
  top: 30%;
  cursor: pointer;
  left: 60%;
  transform: translate(-50%, -50%);
  color: #f06136;
  font-size: 16px;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.3s;
}
.qalunPicContainer:hover .qalunTextOverlay {
  opacity: 1;
}
.flex {
  flex-direction: row-reverse;
  display: flex;
  width: 100%;
  gap: 10px;
  margin-bottom: 5px; /* Added for spacing */
}

.qalunBtn {
  padding: 2%;
  color: rgb(255, 255, 255);
  background-color: #f06136;
  width: 120px;
  font-size: larger;
  font-weight: bold;
  border-radius: 10px;
}
.qalunBtn:hover {
  background-color: #f56f46;
}
/* Media query for mobile devices */
@media (max-width: 767px) {
  .flex {
    flex-direction: column !important;
  }
}

@media (min-width: 992px) {
  .qalun {
    width: 50% !important;
  }
  .qalunPic {
    width: 130%;
    height: 140%;
    margin-right: -25% !important;
  }
}

@media screen and (min-width: 768px) {
  .closeBtn {
    color: rgb(255, 255, 255);
    background-color: #f56f46;
    margin-left: 94.8%;
    position: absolute;
    margin-top: -30px;
    font-weight: bold;
  }
  .closeBtn:hover {
    color: rgb(255, 219, 173);
    background-color: #f06136;
  }
}

img {
  width: 100%;
  height: auto;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 22, 26, 0.527);
}

.singleVideo {
  margin-top: 2%;
  transition: transform 0.2s ease-in-out;
  margin-bottom: 3%;
  padding-left: 3%;
  /* background-color: cadetblue; */
}

.singleVideo:hover {
  transform: scale(1.04);
}

.darsNumber {
  direction: rtl;
  color: #f06136;
  margin-right: 9%;
  font-weight: bolder;
  font-size: 14.5px;
  margin-top: 2%;
}

@media (max-width: 767px) {
  .videoRow {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .videoRow .singleVideo:nth-of-type(1) {
    order: 3;
  }
  .videoRow .singleVideo:nth-of-type(2) {
    order: 2;
  }
  .videoRow .singleVideo:nth-of-type(3) {
    order: 1;
  }
  .videoRow .singleVideo:nth-of-type(4) {
    order: 6;
  }
  .videoRow .singleVideo:nth-of-type(5) {
    order: 5;
  }
  .videoRow .singleVideo:nth-of-type(6) {
    order: 4;
  }
  .videoRow .singleVideo:nth-of-type(7) {
    order: 9;
  }
  .videoRow .singleVideo:nth-of-type(8) {
    order: 8;
  }
  .videoRow .singleVideo:nth-of-type(9) {
    order: 7;
  }
  .videoRow .singleVideo:nth-of-type(10) {
    order: 12;
  }
  .videoRow .singleVideo:nth-of-type(11) {
    order: 11;
  }
  .videoRow .singleVideo:nth-of-type(12) {
    order: 10;
  }
  .videoRow .singleVideo:nth-of-type(13) {
    order: 15;
  }
  .videoRow .singleVideo:nth-of-type(14) {
    order: 14;
  }
  .videoRow .singleVideo:nth-of-type(15) {
    order: 13;
  }
}

.someCorses {
  margin-top: 5%;
  margin-right: 5%;
  margin-bottom: 0.5%;
}
