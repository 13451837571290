.herolarge {
  /* background: #f7ebdd; */
  height: 700px;
  background-image: url("../../public/images/heroBG1.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

.herotitle {
  font-size: xx-large;
  text-align: right;
  color: #172153;
  padding-right: 300px;
  font-family: /*'Thabit', serif*/ Arial, Helvetica, sans-serif;
  font-weight: 1000;
  margin-top: 100px;
}

.herotext {
  margin-top: -90px;
  width: 50%;
  /* padding-right: 100px; */
  margin-left: 45%;
  text-align: right;
  color: #000000;
  font-size: 45px;
  font-weight: 900;
  font-family: "Helvetica, Arial, sans-serif";
}

.herobtn {
  margin-left: 77%;
  margin-top: -65px;
  width: 14rem;
  height: 4rem;
  border-radius: 5px;
  background-color: #2b2468;
  border-radius: 6px;
  border: 1px solid #2b2468;
  font-weight: 800;
  font-size: x-large;
  color: #ff9e5d;
  border: 1px solid;
  font-family: "Helvetica, Arial, sans-serif";
}

.herobtn:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid;
  color: #f05d0e;
  background-color: #1f1569;
}

@media (min-width: 768px) {
  .heromobile {
    display: none;
  }
}
@media (max-width: 768px) {
  .herolarge {
    display: none;
  }
  .heromobile {
    /*background: rgba(248, 248, 248, 0.2);*/
    /* background-image: url("../../public/images/dd.png"); */

    height: 500px;
  }

  .herotitlemob {
    margin-top: 120px;
    text-align: right;
    padding-right: 50px;
    color: #f05d0e;
    font-weight: 900;
    font-size: xx-large;
  }
  .herotextmob {
    padding-right: 20px;
    margin-top: -50px;
    font-family: "Helvetica, Arial, sans-serif";
    text-align: right;
    color: #ffffff;
    font-size: larger;
    font-weight: 900;
    line-height: 40px;
  }

  .herobtnmob {
    margin-top: -50px;
    position: absolute;
    margin-left: 55%;
    width: 8rem;
    height: 2.5rem;
    border-radius: 5px;
    background-color: #f05d0e;
    border-radius: 6px;
    border: 2px solid #ffffff;
    font-weight: 500;
    color: #ffffff;
  }
}
#logoHero {
  position: relative;
  margin-left: 70%;
  margin-top: 2%;
  width: 280px;
  height: 280px;
  z-index: 99;
}
