.dashDivs {
  width: 400px;
  height: 250px;
  background-color: rgba(255, 255, 255, 0.781);
  margin: 2%;
  padding: 1.5%;
  padding-top: 1rem;
  border-radius: 5%;
  color: #000311;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.test {
  width: 400px;
  height: 250px;
  margin: 2%;
  padding: 1.5%;
  border-radius: 5%;
  color: #000311;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  background-color: rgba(255, 255, 255, 0.781);
}

.rowDash {
  text-align: center;
  align-items: center;
}

@media screen and (max-width: 1700px) and (min-width: 750px) {
  .rowDash {
    margin-left: 15%;
  }
}

.dashBtns {
  display: flex;
  margin-top: -10px;
}

.btnDashYes {
  font-size: 22px;
  font-weight: bold;
  color: #ffffff;
  margin: 5px;
  background-color: #36bd3d;
  width: 110px;
  height: 40px;
  border-radius: 10px;
  border: solid 3px #36bd3d;
}
.btnDashYes:hover {
  background-color: rgb(34, 173, 41);
  color: #ffffff;
}

#btnDashYesId {
  margin-left: 23%;
}

.btnDashNo {
  font-size: 22px;
  font-weight: bold;
  color: #ffffff;
  margin: 5px;
  background-color: #f44336;
  width: 110px;
  height: 40px;
  border-radius: 10px;
  border: solid 3px #f44336;
}

.btnDashNo:hover {
  background-color: #e6382c;
  color: #ffffff;
  border: solid 3px #f44336;
}

.QuraniOrangeColor {
  color: #f56f46;
  font-weight: bold;
  direction: rtl;
}

.QuraniOrangeColor2 {
  color: #0d6efd;
  font-weight: bold;
  direction: rtl;
}

.quraniBlueColor {
  color: #172153;
  margin-left: 15%;
  margin-right: 15%;
  font-weight: bold;
  direction: rtl;
}

.grayColor {
  color: #0d6efd;
}

#ahzebNumber {
  margin-top: 2rem;
  margin-bottom: 2.5rem;
}

.arabicText {
  direction: rtl;
}

/* flip card */
.card-container {
  margin-left: 2.5rem;
  position: relative;
  width: 300px;
  height: 200px;
  perspective: 1000px;
}
@media (max-width: 768px) {
  .card-container {
    margin-left: 1rem;
    position: relative;
    width: 300px;
    height: 200px;
    perspective: 1000px;
  }
}
.card-front,
.card-back {
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s;
  /* padding-left: 1rem; */
  padding-top: 1.2rem;
}

.card-back {
  transform: rotateY(180deg);
  margin-left: -15px;

  /* padding-left: 4.5rem; */
}

.card-front {
  margin-left: 15px;
  margin-top: -20px;
}

.card-container.flipped .card-front {
  transform: rotateY(180deg);
}

.card-container.flipped .card-back {
  transform: rotateY(0deg);
}

.back-content {
  font-size: 20px;
  margin-bottom: 20px;
}

.back-content-back-one {
  background-color: #31ac12cc;
  color: #ffffff;
  padding: 15px;
  border-radius: 7px;
}

.back-content-back-two {
  background-color: #bd1a1ab9;
  color: #ffffff;
  padding: 15px;
  border-radius: 7px;
}

.backBtn {
  font-size: 15px;
  font-weight: bolder;
  background-color: #ffffff;
  margin-top: 10px;
  margin-right: 40px;
  margin-left: 40px;
  margin-bottom: -20px;
  font-weight: bold;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  width: 90px;
  height: 30px;
  border-radius: 8px;
  border: solid 3px #172153;
}
.backBtn:hover {
  background-color: #e2e2e2;
}
.card-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashDivs.is-flipped {
  transform-style: preserve-3d;
  transition: transform 0.5s;
  transform: rotateY(180deg);
}

.werdBtns {
  margin-left: 3.7rem;
}

@media (max-width: 768px) {
  .werdBtns {
    margin-left: 2rem;
  }
  .inputWerd {
    height: 35px;
    background-color: #e0dede;
    color: #000000;
    padding: 10px;
    border: none;
    width: 220%;
    outline: none;
    border-radius: 5px;
    direction: rtl;
    margin-left: -3rem !important;
    margin-top: 0.4rem;
  }
  .nasibWerdBtn {
    width: 110%;
    margin-left: -3rem !important;
    margin-right: 1.5rem !important;
  }
}
.inputWerd {
  height: 35px;
  background-color: #e0dede;
  color: #000000;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 5px;
  direction: rtl;
  margin-left: -3rem;
  margin-top: 0.4rem;
}

.nasibWerdBtn {
  margin-left: -8rem;
}

.changeWerdStatusBtn {
  width: auto !important;
  font-weight: bold;
}

.centerBtns {
  text-align: center;
  align-items: center;
  padding: 2%;
}
.modalBtns {
  padding: 8px;
  border-radius: 5px;
  font-weight: bolder;
  width: 70px;
  height: 45px;
}

#yesBtnModal {
  background-color: #36bd3d;
  margin-left: 15px;
}
#yesBtnModal:hover {
  background-color: #2ca332;
}

#noBtnModal {
  background-color: #ffcdcd;
  margin-right: 15px;
}

#noBtnModal:hover {
  background-color: rgb(255, 173, 173);
}
.secondP {
  margin-top: -10px;
  font-weight: bold;
  color: #690700;
}
