.card-img-left {
  width: 45%;
  /* Link to your background image using in the property below! */
  background: scroll center
    url("https://images.unsplash.com/photo-1624489389801-09bb319beae6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDEyfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60");
  background-size: cover;
}

.btn-login {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
}

.saveBtn {
  background-color: #172153;
  color: rgb(255, 255, 255);
}
.saveBtn:hover {
  background-color: #1c2a74;
  color: antiquewhite;
}

.stepOne {
  color: #252525;
  max-width: 380px;
}
.hSix {
  margin: 20px;
  direction: rtl;
}
.hFour {
  margin: 5px;
  direction: rtl;
}
.StepParagraphs {
  font-weight: 600;
  word-spacing: 5px;
  direction: rtl;
  color: rgb(0, 80, 155);
}
.redText {
  color: crimson;
  display: block;
}
#marginTop {
  margin-top: 20px;
}
#hSixId {
  margin-top: -2px;
}
.orderOne {
  order: 1;
}
.orderTwo {
  order: 2;
}
@media (max-width: 767px) {
  .orderOne {
    order: 2;
  }

  .orderTwo {
    order: 1;
  }
}
#selectFromScratsh {
  width: 230px;
}

#refreshBtn {
  margin-left: 130px !important;
}

.hefdhHistory h4 {
  color: #1d272c;
}

.souarDivs {
  background-color: rgba(255, 255, 255, 0.781);
  margin: 1%;
  padding: 3%;
  border-radius: 5%;
  color: #2b2b2b;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  position: relative;
  max-width: 320px;
  /* margin-top: 50px; */
  height: 340px;
}

.hefdhDivs {
  background-color: rgba(255, 255, 255, 0.781);
  margin: 1%;
  padding: 3%;
  max-width: 320px;

  border-radius: 5%;
  color: #000311;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  position: relative;
  /* margin-top: 50px; */
  height: 340px;

  /* z-index: 1; */
}

.hefdhWithUsDivs {
  background-color: rgba(255, 255, 255, 0.781);
  margin-left: -2%;
  width: 130%;
  padding: 3%;
  border-radius: 2%;
  color: #000311;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  position: relative;
  margin-top: 30px;
  padding-top: 2rem !important;
}

.greenDiv {
  background-color: #36bd3d;
  border-radius: 10px;
  padding-top: 8px !important;
  margin-top: -25px;
  top: 0;
  position: absolute;
  width: 22%;
  padding: 2% 3%;
  align-items: center;
  text-align: center;
  color: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.orangeDiv {
  background-color: #fd9a14;
  border-radius: 10px;
  padding-top: 8px !important;
  margin-top: -25px;
  top: 0;
  position: absolute;
  width: 22%;
  padding: 2% 3%;
  align-items: center;
  text-align: center;
  color: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.blueDiv {
  background-color: #3399ee;
  border-radius: 10px;
  padding-top: 8px !important;
  margin-top: -25px;
  top: 0;
  position: absolute;
  width: 22%;
  padding: 2% 3%;
  align-items: center;
  text-align: center;
  color: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.pinkDiv {
  background-color: #e3306f;
  border-radius: 10px;
  padding-top: 8px !important;
  margin-top: -25px;
  top: 0;
  position: absolute;
  width: 6%;
  height: 55px;
  align-items: center;
  text-align: center;
  color: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.hefdhValues {
  color: #3399ee;
  direction: rtl;
}

.nextPrevHefdhBtnTwo {
  padding: 5px;
  border-radius: 5px;
  margin: 10px;
  background-color: #fd9a14;
  font-weight: bold;
}

.nextPrevHefdhBtn {
  padding: 5px;
  border-radius: 5px;
  margin: 10px;
  background-color: #36bd3d;
  font-weight: bold;
}

.nextPrevHefdhBtnTwo:hover {
  background-color: #ffab36;
}
.nextPrevHefdhBtn:hover {
  background-color: #49d34f;
}

@media screen and (max-width: 1200px) {
  .pinkDiv {
    background-color: #e3306f;
    border-radius: 10px;
    padding-top: 8px !important;
    margin-top: -25px;
    top: 0;
    position: absolute;
    width: 22%;
    max-width: 100px;
    padding: 2% 3%;
    align-items: center;
    text-align: center;
    color: #fff !important;
  }
  .hefdhWithUsDivs {
    border-radius: 5%;
    margin-left: 1.5%;
    margin-top: 30px;
  }
  .souarDivs {
    margin-top: 30px;
  }
  .hefdhDivs {
    margin-top: 30px;
  }
}

@media screen and (min-width: 700px) {
  .hefdhContainer {
    margin-left: 5%;
    margin-top: 5%;
  }
}

@media screen and (max-width: 700px) {
  .hefdhContainer {
    margin-left: 1%;
  }
}

.noSouarAvailable {
  margin-top: 20%;
  color: #515257;
  direction: rtl;
}

.currHezbInput {
  width: 6rem;
  background-color: #e0dede;
  color: #000000;
  border-radius: 5px;
  direction: rtl;
}
.currHezbGridParent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  direction: rtl;
}

.currHezbGridChild {
  width: 110%;
  /* margin-right: rem; */
}
.currHezbGridChild2 {
  margin-right: 20%;
}
.HezbHefdh {
  font-weight: bold;
  direction: rtl;
  /* font-size: large; */
}
.souarCurrHezb {
  color: rgb(0, 80, 155) !important;
}

.withQuraniText {
  direction: rtl;
  font-weight: bolder;
  font-size: 25px;
  color: #1d272c;
  margin-bottom: 3%;
}
