#jamiaName {
  margin: 5%;
}

.teacherCard {
  background-color: rgba(216, 120, 42, 0.623);
  width: 200px;
  height: 100px;
  text-align: center;
  align-items: center;
  border-radius: 10px;
  margin-top: 10%;
}

@media only screen and (max-width: 767px) {
  .teacherCard {
    margin-left: 20%;
  }
}

#card1 {
  background-color: rgba(216, 120, 42, 0.623);
}
#card2 {
  background-color: rgba(42, 132, 216, 0.623);
}
#card3 {
  background-color: rgba(216, 77, 42, 0.623);
}
