@media screen and (max-width: 900px) and (min-width: 400px) {
  .calendarComponent {
    padding-left: 80px;
  }
}
@media screen and (max-width: 400px) {
  .calendarComponent {
    padding-left: 0px !important;
    margin-left: -10px !important;
  }
}

@media screen and (min-width: 900px) {
  .calendarComponentMonth {
    padding-left: 35px;
  }
}
@media screen and (max-width: 1200px) and (min-width: 700px) {
  .calendarComponentMonth {
    margin-left: 250px;
  }
}
@media screen and (max-width: 400px) {
  .calendarComponentMonth {
    padding-left: 0px !important;
    margin-left: -10px !important;
  }
}

.loading {
  text-align: center;
  align-items: center;
  padding-top: 15%;
  margin-top: 100px !important;
  /* padding-bottom: 55%; */
  color: #f56f46;
}

.loadingVid {
  text-align: center;
  align-items: center;
  color: #f56f46;
}

.werdTwoButtons {
  display: grid;
  height: 100%;
  place-items: center;
}
.wrapper {
  display: inline-flex;
  background: #fff;
  height: 100px;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  padding: 20px 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.wrapper .option {
  background: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 7px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
}
.wrapper .option .dot {
  height: 20px;
  width: 20px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
}
.wrapper .option .dot::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #172153;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}
input[type="radio"] {
  display: none;
}
#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2 {
  border-color: #172153;
  background: #172153;
}
#option-1:checked:checked ~ .option-1 .dot,
#option-2:checked:checked ~ .option-2 .dot {
  background: #f56f46;
}
#option-1:checked:checked ~ .option-1 .dot::before,
#option-2:checked:checked ~ .option-2 .dot::before {
  opacity: 1;
  transform: scale(1);
}
.wrapper .option span {
  font-size: 20px;
  color: #808080;
}
#option-1:checked:checked ~ .option-1 span,
#option-2:checked:checked ~ .option-2 span {
  color: #fff;
}

@media screen and (min-width: 768px) {
  .next-prev-btns-pc {
    /* display: none; */
    margin-top: 40px;
    margin-left: -35px;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    display: inline-flex;
  }

  .next-prev-btns-phone {
    display: none !important;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    display: inline-flex;
  }

  .nextPrevBtn {
    font-size: 20px;
    font-weight: bolder;
    background-color: #ffffff;
    margin-top: 10px;
    margin-right: 40px;
    margin-left: 40px;
    margin-bottom: -20px;
    font-weight: bold;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    width: 120px;
    height: 40px;
    border-radius: 10px;
    border: solid 3px #172153;
  }
  .nextPrevBtn:hover {
    background-color: #e2e2e2;
  }
}

@media screen and (max-width: 768px) {
  .next-prev-btns-pc {
    display: none !important;
    margin: 20px;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    display: inline-flex;
  }

  .next-prev-btns-phone {
    margin: 20px;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    display: inline-flex;
  }

  .nextPrevBtn {
    font-size: 20px;
    font-weight: bolder;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: -20px;
    width: 120px;
    height: 40px;
    border-radius: 10px;
    border: solid 3px #172153;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
}

.calendarMonthView {
  margin-top: 50px;
}

.titleWerdCont {
  margin-left: auto;
  margin-right: 20%;
  margin-bottom: 5%;
  color: #ced6ff;
  direction: rtl;
}

.werdContent {
  direction: rtl;
  color: #ffd2ae;
}

.rightLeftDivs {
  height: 150px;
}

@media screen and (min-width: 1024px) {
  .testWerd {
    width: 300px;
    height: auto;
    margin: 2%;
    margin-top: 7%;
    padding: 1.5%;
    border-radius: 5%;
    color: #000311;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    background-color: rgba(255, 255, 255, 0.781);
    display: inline-block !important;
    position: absolute;
    direction: rtl;
  }
}

@media screen and (max-width: 1024px) {
  .testWerd {
    width: 300px;
    height: auto;
    margin-bottom: 5%;
    margin-left: 5% !important;
    margin-top: 10%;
    margin-left: -15px;
    padding: 1.5%;
    border-radius: 5%;
    color: #000311;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    background-color: rgba(255, 255, 255, 0.781);
    direction: rtl;
  }
}

.dashWerd {
  font-weight: bold;
}
.redBG {
  background-color: #f54646b7;
}
.greenBG {
  background-color: rgba(22, 187, 22, 0.767);
}
