.navLogo {
  width: 6.5rem;
  margin-top: -7px;
  margin-bottom: -7px;
  margin-right: -75px;
}

.navLogo:hover {
  transform: scale(1.2);
}
#navbarNav {
  margin-left: -8%;
}
.navbar-toggler-icon {
  background-color: #b6aef7;
}

.navbar {
  background-color: #433d7b;
  color: white !important;
  position: fixed;
  width: 100%;
  z-index: 999;
  overflow-x: hidden;
}

.nav-link {
  margin-right: 25px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  direction: rtl !important;
}

.nav-link:hover {
  color: #ff935a !important;
}

@media (max-width: 991px) {
  .navPhoneView {
    direction: rtl;
    margin-top: 5%;
  }
}

.nav-link.active {
  color: #ff935a !important;
}
