.SettingsContainer {
  max-width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  margin-top: 20px;
}

.settingsRow {
  display: flex;
  flex-wrap: wrap;
}

.settingsDivs {
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #f2f2f2;
  cursor: pointer;
  font-weight: bold;
}

.settingsDivs:hover {
  background-color: #e5e5e5;
}

@media screen and (max-width: 768px) {
  .settingsDivs {
    flex-basis: 100%;
  }
}
